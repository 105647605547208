<template>
  <div>
    <van-nav-bar title="提现记录" left-text="返回" left-arrow @click-left="onClickLeft" right-text="提现" @click-right="onRightClick" />
    <van-row>
        <van-col span="4">金额</van-col>
        <van-col span="4">个税</van-col>
        <van-col span="4">实发</van-col>
        <van-col span="4">状态</van-col>
        <van-col span="8">申请提现时间</van-col>
    </van-row>
   <van-row v-for="item in dataList" :key="item.id" style="padding-top:5px;">
        <van-col span="4">{{item.amount.toFixed(2)}}</van-col>
        <van-col span="4">{{item.tax.toFixed(2)}}</van-col>
        <van-col span="4">{{(item.amount-item.tax).toFixed(2)}}</van-col>
        <van-col span="4" v-if="item.status == 0">处理中</van-col>
        <van-col span="4" v-else>已完成</van-col>
        <van-col span="8">{{item.created_time}}</van-col>
    </van-row>
    <div class="my_pager">
    <van-pagination v-model="currentPage" :page-count="pageCount" mode="simple" @change="changePage" />
    </div>
    <CopyRight></CopyRight> 
  </div>
</template>

<script>
import CopyRight from "@/components/CopyRight.vue";
import axios from "axios";
export default {
  data() {
    return {
      dataList: [],
      currentPage: 1,
      pageCount: 1,
    };
  },
  components: {
    CopyRight,
  },
  mounted() {
    this.loadData();
  },

  methods: {
    onClickLeft() {
      this.$router.back();
    },

    onRightClick() {
      this.$router.push('/withdraw/index')
    },

    changePage(page){
      this.currentPage = page;
      this.loadData();
    },

    loadData() {
      let token = window.sessionStorage.getItem("token");
      axios
        .get(
          "api/withdraw/list?page=" +
            this.currentPage +
            "&token=" + token
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.dataList = res.data.data.data_list;
            this.pageCount = res.data.data.total_page;
          }else{
            this.$toast.fail(res.data.message);
          }
        })
    },
  },
};
</script>